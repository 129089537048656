export const WORDS = [
  'latex',
  'rubber',
  'leather',
  'neoprene',
  'paddle',
  'flogging',
  'flogger',
  'safeword',
  'bottom',
  'sadism',
  'masochism',
  'sadist',
  'masochist',
  'submissive',
  'dominant',
  'ponyplay',
  'petplay',
  'pupplay',
  'puppy',
  'dildo',
  'slave',
  'master',
  'plug',
  'bondage',
  'chain',
  'cuffs',
  'collar',
  'chastity',
  'consent',
  'edging',
  'enema',
  'dungeon',
  'fisting',
  'gorean',
  'hogtie',
  'pegging',
  'switch',
  'torture',
  'punish',
  'vanilla',
  'mistress',
  'shibari',
  'hood',
  'mask',
  'blindfold',
  'whipping',
  'whip',
  'catsuit',
  'anal',
  'vaginal',
  'sounding',
  'milking',
]
